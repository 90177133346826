
import { defineComponent } from 'vue'
import ActionButton from '@/components/shared/templates/ActionButton.vue'
import ChatsContentHeaderButtonsWrap
  from '@/components/pages/chats/content/header/headers/ChatsContentHeaderButtonsWrap.vue'

export default defineComponent({
  components: {
    ChatsContentHeaderButtonsWrap,
    ActionButton,
  },
})
