
import { defineComponent, computed, nextTick, ref, onMounted, onUnmounted } from 'vue'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'
import type { ChatLogItemType, ChatLogItemTypes } from '@/definitions/shared/chat/types'
import ChatMessage from '@/components/shared/chat/ChatMessage.vue'
import ChatCall from '@/components/shared/chat/ChatCall.vue'
import ChatEmail from '@/components/shared/chat/ChatEmail.vue'
import ChatMessageNote from '@/components/shared/chat/ChatMessageNote.vue'
import { checkMatch } from '@/services/string'
import moment from 'moment'
import ChatsContentNoMessages from '@/components/pages/chats/content/ChatsContentNoMessages.vue'
import { useContent, useChats } from '@/compositions/chats/chats'
import ChatsContentSystemLog from '@/components/pages/chats/content/ChatsContentSystemLog.vue'
import ChatsContentEmpty from '@/components/pages/chats/content/ChatsContentEmpty.vue'

export default defineComponent({
  components: {
    ChatsContentEmpty,
    ChatsContentSystemLog,
    ChatsContentNoMessages,
    ChatMessageNote,
    TmScrollbar,
    ChatMessage,
    ChatCall,
    ChatEmail,
  },
  emits: ['update:chatLog', 'update:showSelection'],
  setup() {
    const {
      search,
      from,
      showSelection,
      to,
    } = useContent()
    const {
      getActiveChat: activeChat,
      updateChat,
      getLastMessageStatus,
    } = useChats()
    const scrollbarRef = ref()
    const chatLogRef = ref<HTMLElement>()
    let resizeObserver: ResizeObserver

    const getItemComponent = (type: ChatLogItemTypes): string => {
      switch (type) {
        case 'email': return 'chat-email'
        case 'message': return 'chat-message'
        case 'live': return 'chat-message'
        case 'call': return 'chat-call'
        case 'note': return 'chat-message-note'
        default: return 'div'
      }
    }

    const updateScrollbar = (value: number) => {
      nextTick(() => {
        scrollbarRef.value.$el.scrollTop = value
      })
    }

    const setScrollbarOnStart = () => {
      updateScrollbar(scrollbarRef.value.$el.scrollHeight)
    }

    const setScrollbarOnMessage = (e: any) => {
      const padding = 16

      const { y: scrollbarY } = scrollbarRef.value.$el.getBoundingClientRect()
      const messageY = e.getBoundingClientRect().y - scrollbarY
      const scrollbarHeight = scrollbarRef.value.$el.offsetHeight
      const scrollTop = scrollbarRef.value.$el.scrollTop
      const messageHeight = e.offsetHeight

      const position = messageY + messageHeight + padding + scrollTop - scrollbarHeight

      if (scrollTop < position) {
        updateScrollbar(position)
      }
    }

    const updateChatLog = (chatLogItem: ChatLogItemType, chatLogItemUpdated: ChatLogItemType) => {
      if (!activeChat.value) {
        return
      }
      const chatLog = activeChat.value.chatLog.map((e: ChatLogItemType) => e === chatLogItem ? chatLogItemUpdated : e)

      updateChat({ ...activeChat.value, chatLog: chatLog })
      showSelection.value = true
    }

    onMounted(() => {
      setScrollbarOnStart()
      resizeObserver = new ResizeObserver(setScrollbarOnStart)
      resizeObserver.observe(scrollbarRef.value.$el!)
    })
    onUnmounted(() => {
      resizeObserver.disconnect()
    })

    const filteredChatLog = computed((): ChatLogItemType[] => {
      // let previousDate: string

      const chatLog = computed((): ChatLogItemType[] => {
        if (!activeChat.value) { return [] }
        if (search.value) {
          return activeChat.value.chatLog.filter((e: ChatLogItemType) => e.type === 'message' && checkMatch(e.text, search.value))
        }

        if (from.value || to.value) {
          let chatLogByDate: ChatLogItemType[] = activeChat.value.chatLog

          if (from.value) {
            chatLogByDate = chatLogByDate.filter((e: ChatLogItemType) => e.date && moment(from.value).isBefore(moment(e.date)))
          }

          if (to.value) {
            chatLogByDate = chatLogByDate.filter((e: ChatLogItemType) => e.date && moment(e.date).isBefore(moment(to.value)))
          }

          return chatLogByDate
        }

        return activeChat.value.chatLog
      })

      // const chatLogWithDate: ChatLogItemType[] = []

      // chatLog.value.forEach((e: ChatLogItemType) => {
      //   if ((['call', 'message', 'live'].includes(e.type)) && e.date !== previousDate && !previousDate) {
      //     previousDate = e.date
      //
      //     const dateElement: ChatLogItemType = {
      //       date: e.date,
      //       type: 'date',
      //     }
      //
      //     chatLogWithDate.push(dateElement, e)
      //   } else {
      //     chatLogWithDate.push(e)
      //   }
      // })

      return chatLog.value
    })

    return {
      getItemComponent,
      updateScrollbar,
      updateChatLog,
      scrollbarRef,
      setScrollbarOnStart,
      filteredChatLog,
      showSelection,
      setScrollbarOnMessage,
      search,
      chatLogRef,
      getLastMessageStatus,
      activeChat,
    }
  },
})
