
import {
  computed, defineComponent, onMounted, onUnmounted, ref, watch
} from 'vue'
import ChatsSidebarEmpty from '@/components/pages/chats/sidebar/ChatsSidebarEmpty.vue'
import ChatsSidebarHeader from '@/components/pages/chats/sidebar/ChatsSidebarHeader.vue'
import ChatsSidebarContacts from '@/components/pages/chats/sidebar/ChatsSidebarContacts.vue'
import ChatsSidebarLoading from '@/components/pages/chats/ChatsSidebarLoading.vue'
import { useChats, useSidebar } from '@/compositions/chats/chats'
import { useModes } from '@/compositions/modes'
import ChatsSidebarCompose from '@/components/pages/chats/sidebar/ChatsSidebarCompose.vue'
import ChatsSidebarTakeRequest from '@/components/pages/chats/sidebar/ChatsSidebarTakeRequest.vue'
import { chatSidebarFilterItemsList } from '@/definitions/chats/sidebar/data'
import { useRoute } from 'vue-router'
import TmButton from '@/components/shared/TmButton.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: {
    TmButton,
    ChatsSidebarTakeRequest,
    ChatsSidebarCompose,
    ChatsSidebarHeader,
    ChatsSidebarEmpty,
    ChatsSidebarLoading,
    ChatsSidebarContacts,
  },
  setup() {
    const { isEmptyMode, isLoadingMode } = useModes()
    const { isXXlMax } = useBreakpoints()

    const {
      getChatData: chats,
    } = useChats()

    const {
      searchNewChat,
      setActiveFilter,
      getActiveFilter,
    } = useSidebar()

    const route = useRoute()

    const resizerRef = ref()
    const sidebarContainerRef = ref()
    const sidebarTransition = ref(true)
    const sidebarWidth = ref()

    let resizerElement: HTMLElement
    let resizeObserver: ResizeObserver

    const styleUpdate = (elementWidth: number) => {
      const parentWidth = sidebarContainerRef.value.parentElement.offsetWidth

      const minWidth = isXXlMax.value ? parentWidth * 0.3 : parentWidth * 0.2
      const maxWidth = isXXlMax.value ? parentWidth * 0.4 : parentWidth * 0.25

      sidebarWidth.value = elementWidth <= minWidth ? minWidth : elementWidth >= maxWidth ? maxWidth : elementWidth
    }

    const stopResize = () => {
      sidebarTransition.value = true
      window.removeEventListener('mousemove', resize)
    }

    const resize = (e: MouseEvent) => {
      const offset = e.pageX - sidebarContainerRef.value.getBoundingClientRect().left
      sidebarTransition.value = false

      styleUpdate(offset)
    }

    const onResizerMouseDown = (e: MouseEvent) => {
      e.preventDefault()
      window.addEventListener('mousemove', resize)
      window.addEventListener('mouseup', stopResize)
    }

    onMounted(() => {
      resizerElement = resizerRef.value

      resizeObserver = new ResizeObserver(() => {
        styleUpdate(sidebarWidth.value)
      })

      if (sidebarContainerRef.value) {
        resizeObserver.observe(sidebarContainerRef.value)
      }

      resizerElement.addEventListener('mousedown', onResizerMouseDown)
    })

    onUnmounted(() => {
      resizerElement.removeEventListener('mousedown', onResizerMouseDown)
      resizeObserver.disconnect()
    })

    const checkRoute = () => {
      const activeFilterItem = chatSidebarFilterItemsList.find(item => item.routeName === route.name)
      setActiveFilter(activeFilterItem || chatSidebarFilterItemsList[1])
    }
    checkRoute()

    watch(route, () => {
      checkRoute()
    })

    const isOpenRoute = computed(() => getActiveFilter.value.routeName === 'base.chats.open')
    const computedEmptyTitle = computed(() => {
      if (isOpenRoute.value) {
        return 'Start a new chat'
      }
      return `No ${getActiveFilter.value.shortLabel} chats`
    })
    const computedEmptyText = computed(() => {
      if (isOpenRoute.value) {
        return 'Currently there are no open chats.\nStart a new chat by clicking the icon above.'
      }
      return `Currently there are no ${getActiveFilter.value.shortLabel} chats.\nTo switch to open chats, click on the link below.`
    })

    return {
      computedEmptyTitle,
      computedEmptyText,
      isOpenRoute,
      useSidebar,
      searchNewChat,
      chats,
      sidebarTransition,
      isLoadingMode,
      isEmptyMode,
      getActiveFilter,
      resizerRef,
      sidebarContainerRef,
      sidebarWidth,
    }
  },
})
