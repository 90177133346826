
import { defineComponent, computed, ref, watch } from 'vue'
import ChatsContentHeader from '@/components/pages/chats/content/ChatsContentHeader.vue'
import ChatsContentLog from '@/components/pages/chats/content/ChatsContentLog.vue'
import ChatsContentLogLoading from '@/components/pages/chats/content/ChatsContentLogLoading.vue'
import ChatsContentEmpty from '@/components/pages/chats/content/ChatsContentEmpty.vue'
import { useChats, useContent } from '@/compositions/chats/chats'
import { useModes } from '@/compositions/modes'
import ChatsSystemViewEnded from '@/components/pages/chats/content/systemViews/ChatsSystemViewEnded.vue'
import ChatsSystemViewWaiting from '@/components/pages/chats/content/systemViews/ChatsSystemViewWaiting.vue'
import ChatsSystemViewSolved from '@/components/pages/chats/content/systemViews/ChatsSystemViewSolved.vue'
import ChatsSystemViewMissed from '@/components/pages/chats/content/systemViews/ChatsSystemViewMissed.vue'
import ChatsSystemViewInstagramDisconnected from '@/components/pages/chats/content/systemViews/ChatsSystemViewInstagramDisconnected.vue'
import ChatsContentBackground from '@/components/pages/chats/content/ChatsContentBackground.vue'
import ChatsContentEditor from '@/components/pages/chats/content/ChatsContentEditor.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'

export default defineComponent({
  components: {
    ChatsContentEditor,
    TmTooltip,
    ChatsContentBackground,
    ChatsSystemViewEnded,
    ChatsContentLogLoading,
    ChatsContentEmpty,
    ChatsContentHeader,
    ChatsContentLog,
  },
  emits: ['update:chats', 'toggle-note-state'],
  setup() {
    const { isLoadingMode } = useModes()
    const {
      getActiveChat: activeChat,
      clearActiveChat,
    } = useChats()
    clearActiveChat()
    const {
      showDate,
      from,
      to,
      showSearch,
      search,
      showSelection,
      toggleAllSelected,
    } = useContent()

    const chatsContentLog = ref()

    const lastMessage = computed(() => activeChat.value?.chatLog.slice().pop())
    const lastMessageType = computed(() => {
      const isLive = activeChat.value?.type === 'live'
      const callType = lastMessage.value?.callType

      if (isLive && callType) {
        return callType
      }
      return false
    })

    const systemViewComponent = computed(() => {
      const viewMap = {
        missed: ChatsSystemViewMissed,
        ended: ChatsSystemViewEnded,
        solved: ChatsSystemViewSolved,
        waiting: ChatsSystemViewWaiting,
        instagramDisconnected: ChatsSystemViewInstagramDisconnected,
        open: false,
      }
      return activeChat.value?.instagramStatus === 'disconnected' ? viewMap.instagramDisconnected : (lastMessageType.value && viewMap[lastMessageType.value])
    })

    watch(() => activeChat.value, (val, prevVal) => {
      if (!prevVal || (val && val.id !== prevVal.id)) {
        setTimeout(() => chatsContentLog.value.setScrollbarOnStart(), 1)

        showDate.value = false
        from.value = null
        to.value = null
        showSelection.value = false
        showSearch.value = false
        search.value = ''
        toggleAllSelected(true)
      }
    })

    return {
      isLoadingMode,
      chatsContentLog,
      activeChat,
      showSelection,
      lastMessageType,
      systemViewComponent,
    }
  },
})
