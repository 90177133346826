
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmBadgeCounter from '@/components/shared/TmBadgeCounter.vue'
import type { RouteLocationRaw } from 'vue-router'

export default defineComponent({
  components: {
    TmBadgeCounter,
    TmDropdownItem,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    isMissed: {
      type: Boolean,
    },
    isActive: {
      type: Boolean,
    },
    to: {
      type: Object as PropType<RouteLocationRaw>,
    },
    additionalText: {
      type: Number,
    },
  },
})
