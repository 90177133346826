
import { defineComponent, ref } from 'vue'
import TmSearch from '@/components/shared/TmSearch.vue'
import { useSidebar } from '@/compositions/chats/chats'
import TmButton from '@/components/shared/TmButton.vue'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    TmButton,
    TmSearch,
  },
  setup() {
    const { openModal } = useModals()
    const {
      search,
    } = useSidebar()
    const showCounter = ref(false)
    const openFilters = () => {
      openModal('chatsFilters')
      showCounter.value = true
    }

    return {
      openModal,
      showCounter,
      search,
      openFilters,
    }
  },
})
