
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    icon: {
      type: String,
      default: 'question_answer',
    },
    title: {
      type: String,
      default: 'Start a new chat',
    },
    text: {
      type: String,
      default: 'Currently there are no chats.\nStart a new chat by clicking the icon above.',
    },
  },
})
