<template>
  <div class="chats-content-header-empty">
    <div class="chats-content-header-empty__avatar" />

    <div>
      <div class="chats-content-header-empty__name" />
      <div class="chats-content-header-empty__info" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.chats-content-header-empty {
  display: flex;
  align-items: center;

  &__avatar {
    width: 44px;
    height: 44px;
    background: $gray1;
    margin-right: 10px;
    border-radius: 50%;
  }

  &__name {
    width: 170px;
    height: 15px;
    background: $gray2;
    border-radius: $border-radius-lg;
    margin-bottom: 8px;
  }

  &__info {
    width: 96px;
    height: 8px;
    border-radius: $border-radius-lg;
    background: $gray1;
  }
}
</style>
