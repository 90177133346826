
import { defineComponent, computed, onMounted } from 'vue'
import ChatsSidebarHeaderMain from '@/components/pages/chats/sidebar/header/ChatsSidebarHeaderMain.vue'
import ChatsSidebarHeaderSelection from '@/components/pages/chats/sidebar/header/ChatsSidebarHeaderSelection.vue'
import { useSidebar } from '@/compositions/chats/chats'
import { useRoute } from 'vue-router'

export default defineComponent({
  components: {
    ChatsSidebarHeaderMain,
    ChatsSidebarHeaderSelection,
  },
  setup() {
    const {
      showNewChat,
      showSelection,
    } = useSidebar()
    const route = useRoute()

    onMounted(() => {
      if (route.params.startChat) {
        showNewChat()
      }
    })

    const headerComponent = computed((): string => {
      if (showSelection.value) {
        return 'chats-sidebar-header-selection'
      }

      return 'chats-sidebar-header-main'
    })

    return {
      headerComponent,
    }
  },
})
