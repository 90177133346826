
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import ChatsSystemViewTemplate from '@/components/pages/chats/content/systemViews/ChatsSystemViewTemplate.vue'
import { useModals } from '@/compositions/modals'
import { useRouter } from 'vue-router'
import { useChats } from '@/compositions/chats/chats'

export default defineComponent({
  components: { ChatsSystemViewTemplate, TmButton },
  setup() {
    const { openModal } = useModals()
    const {
      getActiveChat: activeChat,
      setActiveChat,
    } = useChats()
    const router = useRouter()

    const takeChat = () => {
      const backupVal = Object.assign({}, activeChat.value)
      backupVal.chatLog[backupVal.chatLog.length - 1].callType = 'open'
      setActiveChat(backupVal)
      router.push({ name: 'base.chats.open' })
    }

    return {
      takeChat,
      openModal,
    }
  },
})
