
import { defineComponent, computed } from 'vue'
import ChatAvatar from '@/components/shared/chat/ChatAvatar.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import { useModals } from '@/compositions/modals'
import { useBreakpoints } from '@/compositions/breakpoints'
import { useChats, useCalls } from '@/compositions/chats/chats'
import { tmAutoSizeIgnoreClass } from '@/definitions/shared/autoSize/data'
import { useRoute } from 'vue-router'
import ChatsContentHeaderButtonsDefault
  from '@/components/pages/chats/content/header/headers/ChatsContentHeaderButtonsDefault.vue'
import ChatsContentHeaderButtonsSolved
  from '@/components/pages/chats/content/header/headers/ChatsContentHeaderButtonsSolved.vue'

export default defineComponent({
  components: {
    ChatAvatar,
    TmDropdown,
    TmDropdownItem,
    TmButton,
    ChatsContentHeaderButtonsDefault,
    ChatsContentHeaderButtonsSolved,
  },
  setup() {
    const { isSmMax, isMdMax } = useBreakpoints()
    const route = useRoute()

    const {
      getActiveCall: activeCall,
    } = useCalls()

    const {
      showMobileChat,
      getActiveChat: activeChat,
    } = useChats()

    const headerBtnsComponent = computed(() => {
      switch (route.name) {
        case 'base.chats.solved': return 'ChatsContentHeaderButtonsSolved'
        case 'base.chats.waiting-now': return undefined
        default: return 'ChatsContentHeaderButtonsDefault'
      }
    })

    const chatSender = computed(() => activeChat.value ? activeChat.value.chatSender : null)

    const channelIcon = computed(() => {
      switch (activeChat.value?.channel) {
        case 'meta': return require('@/assets/images/chats/meta.svg')
        case 'mobile': return require('@/assets/images/chats/whatsapp.svg')
        case 'outlook': return require('@/assets/images/chats/message.svg')
        case 'email': return require('@/assets/images/chats/message.svg')
        case 'instagram': return require('@/assets/images/chats/instagram-bordered.svg')
        case 'liveChatGlobal': return require('@/assets/images/chats/live-chat-global.svg')
        default: return require('@/assets/images/chats/chat.svg')
      }
    })

    return {
      activeCall,
      isMdMax,
      activeChat,
      useModals,
      isSmMax,
      chatSender,
      showMobileChat,
      tmAutoSizeIgnoreClass,
      headerBtnsComponent,
      channelIcon,
    }
  },
})
