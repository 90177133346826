import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_action_button = _resolveComponent("action-button")!
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!

  return (_openBlock(), _createBlock(_component_tm_dropdown, { class: "ml-2" }, {
    menu: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentActionsList, (item, key) => {
        return (_openBlock(), _createBlock(_component_tm_dropdown_item, {
          key: key,
          icon: item.icon,
          label: item.label,
          onClick: ($event: any) => (item?.clickAction())
        }, null, 8, ["icon", "label", "onClick"]))
      }), 128))
    ]),
    default: _withCtx(() => [
      _createVNode(_component_action_button, { icon: "more_vert" })
    ]),
    _: 1
  }))
}