import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a8d088b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chats-sidebar-loading" }
const _hoisted_2 = { class: "flex-grow-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_skeleton = _resolveComponent("tm-skeleton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (i) => {
      return _createElementVNode("div", {
        key: i,
        class: "chats-sidebar-loading__preview"
      }, [
        _createVNode(_component_tm_skeleton, {
          type: "circle",
          width: "40px",
          height: "40px",
          class: "mr-3"
        }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_tm_skeleton, {
            type: "QChip",
            class: "mb-2",
            "random-width": "",
            height: "15px"
          }),
          _createVNode(_component_tm_skeleton, {
            type: "QChip",
            "random-width": "",
            height: "8px"
          })
        ])
      ])
    }), 64))
  ]))
}