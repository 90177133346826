import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "ml-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_badge_counter = _resolveComponent("tm-badge-counter")!
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!

  return (_openBlock(), _createBlock(_component_tm_dropdown_item, { to: _ctx.to }, {
    default: _withCtx(() => [
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.isActive ? 'primary--text' : 'neutral--text')
      }, _toDisplayString(_ctx.label), 3),
      _createElementVNode("div", _hoisted_1, [
        (_ctx.isMissed)
          ? (_openBlock(), _createBlock(_component_tm_badge_counter, {
              key: 0,
              class: "ml-auto",
              label: _ctx.additionalText
            }, null, 8, ["label"]))
          : (_openBlock(), _createElementBlock("span", {
              key: 1,
              class: _normalizeClass(_ctx.isActive ? 'primary--text' : 'distinct--text')
            }, _toDisplayString(_ctx.additionalText), 3))
      ])
    ]),
    _: 1
  }, 8, ["to"]))
}