
import { defineComponent, ref } from 'vue'
import TmFieldsContainer from '@/components/shared/tmFieldsContainer/TmFieldsContainer.vue'
import TmEllipsis from '@/components/shared/TmEllipsis.vue'
import Highlight from '@/components/shared/Highlight.vue'
import TmSystemInfo from '@/components/shared/TmSystemInfo.vue'
import TmFlag from '@/components/shared/TmFlag.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import { assigneeOptions, chatStatusOptions, tagsOptions } from '@/definitions/_general/_data/optionsList'
import { useChats } from '@/compositions/chats/chats'

export default defineComponent({
  components: {
    TmSystemInfo,
    Highlight,
    TmFieldsContainer,
    TmEllipsis,
    TmFlag,
    TmTooltip,
  },
  props: {
    search: {
      type: String,
    },
  },
  setup() {
    const { getActiveChat: activeChat } = useChats()
    const statusValue = ref(chatStatusOptions.find((e) => e.name === 'Open'))
    const statusOptions = chatStatusOptions

    const assigneeValue = ref<any>(assigneeOptions[2])

    const tagsValue = ref([])

    const systemInfo = [
      {
        label: 'Chat ID',
        text: '324342',
      },
      {
        label: 'Created',
        text: '11 Jul, 3:14 am',
      },
      {
        label: 'Last updated',
        text: 'Yesterday, 11:33 pm',
      },
      {
        label: 'Updated by',
        text: 'Robert Overit',
      },
    ]

    return {
      activeChat,
      systemInfo,
      statusValue,
      statusOptions,
      tagsValue,
      tagsOptions,
      assigneeOptions,
      assigneeValue,
    }
  },
})
