
import { defineComponent, ref } from 'vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import ChatsSidebarFilterItem from '@/components/pages/chats/sidebar/ChatsSidebarFilterItem.vue'
import { useRoute } from 'vue-router'
import { useChats, useSidebar } from '@/compositions/chats/chats'

import { chatSidebarFilterItemsList } from '@/definitions/chats/sidebar/data'

export default defineComponent({
  components: {
    ChatsSidebarFilterItem,
    TmDropdown,
  },
  setup() {
    const route = useRoute()
    const { clearActiveChat } = useChats()
    const { getActiveFilter } = useSidebar()
    const dropdownValue = ref(false)

    return {
      route,
      chatSidebarFilterItemsList,
      clearActiveChat,
      getActiveFilter,
      dropdownValue,
    }
  },
})
