
import { defineComponent } from 'vue'
import TmEmptyState from '@/components/shared/TmEmptyState.vue'
import { useModals } from '@/compositions/modals'
import TmAutoSizePanel from '@/components/shared/autoSize/TmAutoSizePanel.vue'
import TmAutoSizePanelItemButton from '@/components/shared/autoSize/panel/TmAutoSizePanelItemButton.vue'

export default defineComponent({
  name: 'ChatsSystemViewTemplate',
  components: { TmAutoSizePanelItemButton, TmAutoSizePanel, TmEmptyState },
  props: {
    icon: {
      type: String,
    },
    title: {
      type: String,
    },
  },
  setup() {
    const { openModal } = useModals()

    const openModalSolve = () => {
      openModal('confirmation', {
        modalTitle: 'Mark chat as solved',
        btnText: 'Mark as solved',
        btnColor: 'blue',
        text: [
          'You have an ongoing conversation with ',
          {
            text: 'Cody Fisher.\n',
            style: 'semi-bold',
          },
          'Are you sure you would like to mark this chat as solved? This\n' +
          'action will end the chat for both parties.',
        ],
      })
    }
    const handleTask = () => {
      openModal('tasks', {
        description: 'Related to the chat 332211 with Cody Fisher.',
      })
    }

    return {
      openModal,
      openModalSolve,
      handleTask,
    }
  },
})
