import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31da0b90"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chats-content-log" }
const _hoisted_2 = {
  key: 0,
  ref: "chatLogRef",
  class: "d-flex py-4 flex-column w100pr align-start h100pr justify-end"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chats_content_system_log = _resolveComponent("chats-content-system-log")!
  const _component_chat_message_note = _resolveComponent("chat-message-note")!
  const _component_chats_content_empty = _resolveComponent("chats-content-empty")!
  const _component_chats_content_no_messages = _resolveComponent("chats-content-no-messages")!
  const _component_tm_scrollbar = _resolveComponent("tm-scrollbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_scrollbar, {
      ref: "scrollbarRef",
      "remove-scrollbar-width": ""
    }, {
      default: _withCtx(() => [
        (_ctx.filteredChatLog.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_ctx.activeChat?.type === 'live')
                ? (_openBlock(), _createBlock(_component_chats_content_system_log, {
                    key: 0,
                    type: "start",
                    colorful: ""
                  }))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredChatLog, (item, i) => {
                return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getItemComponent(item.type)), _mergeProps({ key: i }, item, {
                  "show-selection": _ctx.showSelection,
                  search: _ctx.search,
                  colorful: "",
                  onSetScrollbar: _ctx.setScrollbarOnMessage,
                  "onUpdate:selected": ($event: any) => (_ctx.updateChatLog(item, { ...item, selected: !item.selected }))
                }), null, 16, ["show-selection", "search", "onSetScrollbar", "onUpdate:selected"]))
              }), 128)),
              (_ctx.activeChat?.type === 'live')
                ? (_openBlock(), _createBlock(_component_chats_content_system_log, {
                    key: 1,
                    type: _ctx.getLastMessageStatus,
                    class: "mt-4",
                    colorful: ""
                  }, null, 8, ["type"]))
                : _createCommentVNode("", true),
              (_ctx.getLastMessageStatus === 'ended')
                ? (_openBlock(), _createBlock(_component_chat_message_note, {
                    key: 2,
                    class: "mt-4",
                    text: "Great client"
                  }))
                : _createCommentVNode("", true)
            ], 512))
          : (_ctx.search)
            ? (_openBlock(), _createBlock(_component_chats_content_empty, {
                key: 1,
                title: "No results with this request",
                text: "Please try to change your search request",
                icon: "search_off"
              }))
            : (_openBlock(), _createBlock(_component_chats_content_no_messages, { key: 2 }))
      ]),
      _: 1
    }, 512)
  ]))
}