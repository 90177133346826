import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-center" }
const _hoisted_2 = { class: "primary--text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_action_button = _resolveComponent("action-button")!
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_button, {
      size: "large",
      onClick: _ctx.primaryBtn.clickAction
    }, {
      default: _withCtx(() => [
        _createVNode(_component_tm_icon, {
          name: _ctx.primaryBtn.icon,
          left: "",
          class: "primary--text"
        }, null, 8, ["name"]),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.primaryBtn.text), 1)
      ]),
      _: 1
    }, 8, ["onClick"]),
    _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps({ initCall: _ctx.initCall, searchMessages: _ctx.searchMessages, createTask: _ctx.createTask, selectMessages: _ctx.selectMessages }))),
    _createVNode(_component_tm_dropdown, { class: "ml-2" }, {
      menu: _withCtx(() => [
        _renderSlot(_ctx.$slots, "menu", _normalizeProps(_guardReactiveProps({
            openChatsDownload: _ctx.openChatsDownload,
            openBlockModal: _ctx.openBlockModal,
            openUnsubscribeContactModal: _ctx.openUnsubscribeContactModal,
            deleteChat: _ctx.deleteChat,
            openBlockIPModal: _ctx.openBlockIPModal,
            assignChat: _ctx.assignChat,
            createTask: _ctx.createTask,
            assigneeModal: _ctx.assigneeModal,
          })), () => [
          _createVNode(_component_tm_dropdown_item, {
            icon: "tmi-layers-tasks",
            label: "Create task",
            class: "lt-md",
            onClick: _ctx.createTask
          }, null, 8, ["onClick"]),
          _createVNode(_component_tm_dropdown_item, {
            icon: "mark_as_unread",
            class: "lt-md",
            label: "Unread"
          }),
          _createVNode(_component_tm_dropdown_item, {
            class: "lt-lg",
            icon: "volume_off",
            label: "Mute"
          }),
          _createVNode(_component_tm_dropdown_item, {
            class: "lt-lg",
            icon: "search",
            label: "Search messages",
            onClick: _ctx.searchMessages
          }, null, 8, ["onClick"]),
          _createVNode(_component_tm_dropdown_item, {
            icon: "tmi-person-assign",
            label: "Assign",
            onClick: _ctx.assignChat
          }, null, 8, ["onClick"]),
          _createVNode(_component_tm_dropdown_item, {
            label: "Download transcript",
            icon: "get_app",
            onClick: _ctx.openChatsDownload
          }, null, 8, ["onClick"]),
          _createVNode(_component_tm_dropdown_item, {
            label: "Block contact",
            icon: "block",
            onClick: _ctx.openBlockModal
          }, null, 8, ["onClick"]),
          _createVNode(_component_tm_dropdown_item, {
            label: "Block IP",
            icon: "do_not_disturb_on",
            onClick: _ctx.openBlockIPModal
          }, null, 8, ["onClick"]),
          _createVNode(_component_tm_dropdown_item, {
            label: "Delete chat",
            icon: "delete",
            onClick: _ctx.deleteChat
          }, null, 8, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_action_button, { icon: "more_vert" })
      ]),
      _: 3
    }),
    (!_ctx.showChatDetails)
      ? (_openBlock(), _createBlock(_component_action_button, {
          key: 0,
          icon: "tmi-sidebar-right",
          class: "ml-2",
          text: "Views details",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showChatDetails = !_ctx.showChatDetails))
        }))
      : _createCommentVNode("", true)
  ]))
}