import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-41761d98"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chats-content-system-log" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_stylized_text = _resolveComponent("tm-stylized-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messagesList, (message, key) => {
      return (_openBlock(), _createElementBlock("div", {
        key: key,
        class: _normalizeClass(["chats-content-system-log__item", {
        'chats-content-system-log__item--colorful': _ctx.colorful,
      }])
      }, [
        _createVNode(_component_tm_stylized_text, { text: message }, null, 8, ["text"])
      ], 2))
    }), 128))
  ]))
}