
import { defineComponent } from 'vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmSearch from '@/components/shared/TmSearch.vue'
import { useContent } from '@/compositions/chats/chats'

export default defineComponent({
  components: {
    TmDropdownItem,
    TmDropdown,
    TmButton,
    TmSearch,
  },
  setup() {
    const {
      showSearch,
      search,
    } = useContent()

    const hideSearch = () => {
      showSearch.value = false
      search.value = ''
    }

    const dates = ['All time', 'Today', 'Yesterday', 'This week', 'Last week', 'This month', 'Previous month', 'Last 60 days', 'Custom']

    return {
      dates,
      showSearch,
      hideSearch,
      search,
    }
  },
})
