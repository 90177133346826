
import { defineComponent } from 'vue'
import ChatPreview from '@/components/shared/chat/ChatPreview.vue'
import SearchNoResults from '@/components/shared/search/SearchNoResults.vue'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'
import { useChats, useSidebar } from '@/compositions/chats/chats'
import type { ChatType } from '@/definitions/shared/chat/types'

export default defineComponent({
  components: {
    TmScrollbar,
    ChatPreview,
    SearchNoResults,
  },
  setup() {
    const {
      getChatData: chats,
      getActiveChat: activeChat,
      updateChat,
      setActiveChat,
      showMobileChat,
    } = useChats()

    const {
      showSelection,
      search,
    } = useSidebar()

    const handleClick = (chat: ChatType) => {
      setActiveChat(chat)
      showMobileChat.value = true
    }

    return {
      handleClick,
      showSelection,
      search,
      updateChat,
      activeChat,
      chats,
      setActiveChat,
    }
  },
})
