import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-center w100pr" }
const _hoisted_2 = { class: "flex-grow-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_skeleton = _resolveComponent("tm-skeleton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_skeleton, {
      type: "circle",
      width: "40px",
      height: "40px",
      class: "mr-3",
      animation: _ctx.isLoadingMode ? 'wave' : 'none'
    }, null, 8, ["animation"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_tm_skeleton, {
        type: "QChip",
        width: "140px",
        height: "15px",
        class: "mb-2",
        animation: _ctx.isLoadingMode ? 'wave' : 'none'
      }, null, 8, ["animation"]),
      _createVNode(_component_tm_skeleton, {
        type: "QChip",
        width: "85px",
        height: "8px",
        animation: _ctx.isLoadingMode ? 'wave' : 'none'
      }, null, 8, ["animation"])
    ])
  ]))
}