
import { computed, defineComponent, ref } from 'vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import TmButton from '@/components/shared/TmButton.vue'
import type { EditorButtonType } from '@/definitions/shared/editor/types'
import { editorBtns } from '@/definitions/shared/editor/data'
import { useModals } from '@/compositions/modals'
import { useModes } from '@/compositions/modes'
import { useChats } from '@/compositions/chats/chats'

export default defineComponent({
  components: {
    TmTooltip,
    TmButton,
  },
  setup() {
    const { isLoadingMode } = useModes()
    const { openModal } = useModals()
    const noteState = ref()
    const editor = ref()
    const showWhatsappAlert = ref(true)
    const whatsappSessionEnded = ref(false)

    const showAgentTypingComputed = computed(() => activeChat.value?.channel === 'mobile' && !whatsappSessionEnded.value)
    const placeholderComputed = computed(() => whatsappSessionEnded.value ? 'Currently, business-initiated chats are not supported. Only contact can initiate WhatsApp conversations.' : 'Type your text message here')
    const showWhatsappAlertComputed = computed(() => activeChat.value?.channel === 'mobile' && showWhatsappAlert.value)
    const showInstagramAlertComputed = computed(() => activeChat.value?.channel === 'instagram' && activeChat.value?.instagramStatus === 'error')
    const disableComputed = computed(() => isLoadingMode.value || !activeChat.value || whatsappSessionEnded.value)

    const {
      getActiveChat: activeChat,
    } = useChats()

    const btnsChatList = computed<EditorButtonType[]>(() => [
      editorBtns.emoji,
      editorBtns.attachment,
      editorBtns.assignment,
      editorBtns.tags,
      editorBtns.ai,
      {
        ...editorBtns.schedule,
        showInDropdown: true,
        disable: !editor.value?.length,
      },
      {
        ...editorBtns.preview,
        showInDropdown: true,
        disable: !editor.value?.length,
        clickEvent: () => {
          openModal('newMessagePreview', {
            content: editor,
          })
        },
      },
      {
        ...editorBtns.note,
        permanent: true,
        showLabel: true,
      },
    ])

    const btnsNoteList: EditorButtonType[] = [
      editorBtns.text,
      editorBtns.emoji,
      editorBtns.attachment,
      editorBtns.assignment,
      editorBtns.tags,
      editorBtns.mention,
      editorBtns.ai,
      {
        ...editorBtns.note,
        permanent: true,
        showLabel: true,
      },
    ]

    return {
      placeholderComputed,
      showAgentTypingComputed,
      showWhatsappAlertComputed,
      activeChat,
      isLoadingMode,
      btnsNoteList,
      noteState,
      editor,
      btnsChatList,
      whatsappSessionEnded,
      showInstagramAlertComputed,
      disableComputed,
      showWhatsappAlert,
    }
  },
})
