import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c136b2a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "subhead-semi-bold-16 mb-14px" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_auto_size_panel_item_button = _resolveComponent("tm-auto-size-panel-item-button")!
  const _component_tm_auto_size_panel = _resolveComponent("tm-auto-size-panel")!
  const _component_tm_empty_state = _resolveComponent("tm-empty-state")!

  return (_openBlock(), _createBlock(_component_tm_empty_state, {
    "background-lift": "",
    size: "xSmall"
  }, {
    icon: _withCtx(() => [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_component_tm_icon, {
            key: 0,
            class: "tm-empty-state__icon",
            "custom-size": "60px",
            name: _ctx.icon
          }, null, 8, ["name"]))
        : _createCommentVNode("", true)
    ]),
    footer: _withCtx(() => [
      (_ctx.$slots.default)
        ? _renderSlot(_ctx.$slots, "default", { key: 0 }, undefined, true)
        : (_openBlock(), _createBlock(_component_tm_auto_size_panel, {
            key: 1,
            "min-hidden-items": 2,
            class: "justify-center"
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "primary-btn", {}, () => [
                _createVNode(_component_tm_auto_size_panel_item_button, {
                  color: "blue",
                  text: "Reopen",
                  "btn-size": "",
                  icon: "replay"
                })
              ], true),
              _renderSlot(_ctx.$slots, "other-btn", {}, () => [
                _createVNode(_component_tm_auto_size_panel_item_button, {
                  icon: "article",
                  text: "Add note",
                  "btn-size": "",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openModal('note', { modalTitle: 'Add note' })))
                }),
                _createVNode(_component_tm_auto_size_panel_item_button, {
                  icon: "tmi-layers-tasks",
                  text: "Create task",
                  "btn-size": "",
                  onClick: _ctx.handleTask
                }, null, 8, ["onClick"])
              ], true)
            ]),
            _: 3
          }))
    ]),
    default: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.title), 1)
    ]),
    _: 3
  }))
}