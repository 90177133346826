import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_action_button = _resolveComponent("action-button")!
  const _component_chats_content_header_buttons_wrap = _resolveComponent("chats-content-header-buttons-wrap")!

  return (_openBlock(), _createBlock(_component_chats_content_header_buttons_wrap, null, {
    default: _withCtx(({ searchMessages, createTask }) => [
      _createVNode(_component_action_button, {
        icon: "tmi-layers-tasks",
        text: "Create task",
        class: "gt-sm",
        onClick: createTask
      }, null, 8, ["onClick"]),
      _createVNode(_component_action_button, {
        icon: "mark_as_unread",
        class: "gt-sm",
        text: "Unread"
      }),
      _createVNode(_component_action_button, {
        class: "gt-md",
        icon: "volume_off",
        text: "Mute"
      }),
      _createVNode(_component_action_button, {
        class: "gt-md",
        icon: "search",
        text: "Search messages",
        onClick: searchMessages
      }, null, 8, ["onClick"])
    ]),
    _: 1
  }))
}