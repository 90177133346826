import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e31dfff"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chats-content" }
const _hoisted_2 = { class: "d-flex overflow-hidden flex-column relative flex-grow-1 flex-shrink-1" }
const _hoisted_3 = { class: "pb-4 px-4 relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chats_content_header = _resolveComponent("chats-content-header")!
  const _component_chats_content_background = _resolveComponent("chats-content-background")!
  const _component_chats_content_log = _resolveComponent("chats-content-log")!
  const _component_chats_content_log_loading = _resolveComponent("chats-content-log-loading")!
  const _component_chats_content_empty = _resolveComponent("chats-content-empty")!
  const _component_chats_content_editor = _resolveComponent("chats-content-editor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_chats_content_header),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_chats_content_background),
      (!_ctx.isLoadingMode && _ctx.activeChat)
        ? (_openBlock(), _createBlock(_component_chats_content_log, {
            key: 0,
            ref: "chatsContentLog",
            class: "relative"
          }, null, 512))
        : (_ctx.isLoadingMode)
          ? (_openBlock(), _createBlock(_component_chats_content_log_loading, { key: 1 }))
          : (_openBlock(), _createBlock(_component_chats_content_empty, { key: 2 })),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.systemViewComponent)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.systemViewComponent), { key: 0 }))
          : (_openBlock(), _createBlock(_component_chats_content_editor, { key: 1 }))
      ])
    ])
  ]))
}