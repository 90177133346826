import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-96025da6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-center w100pr justify-between" }
const _hoisted_2 = { class: "d-flex align-center overflow-hidden py-1 my-n1" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "overflow-hidden" }
const _hoisted_5 = { class: "d-flex align-center overflow-hidden" }
const _hoisted_6 = { class: "chats-content-header-main__name" }
const _hoisted_7 = { class: "body-text-regular-14 distinct--text lh-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_chat_avatar = _resolveComponent("chat-avatar")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["d-flex align-center overflow-hidden py-1 my-n1", {
        'ml-n2': _ctx.isSmMax,
      }])
    }, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.isSmMax)
          ? (_openBlock(), _createBlock(_component_tm_button, {
              key: 0,
              flat: "",
              color: "transparent",
              "icon-only": "",
              class: "mr-2",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showMobileChat = false))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_tm_icon, {
                  name: "chevron_left",
                  size: "xLarge",
                  class: "distinct--text"
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_router_link, { to: { name: 'base.contacts.contactDetails', params: { id: '1' } } }, {
          default: _withCtx(() => [
            (_ctx.chatSender)
              ? (_openBlock(), _createBlock(_component_chat_avatar, {
                  key: 0,
                  "chat-sender": _ctx.chatSender,
                  class: "mr-3"
                }, {
                  status: _withCtx(() => [
                    (_ctx.channelIcon)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          src: _ctx.channelIcon
                        }, null, 8, _hoisted_3))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["chat-sender"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["to"]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_router_link, { to: { name: 'base.contacts.contactDetails', params: { id: '1' } } }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.chatSender?.person && _ctx.chatSender?.person.fullName ? _ctx.chatSender?.person.fullName : _ctx.chatSender?.phone), 1)
              ]),
              _: 1
            }, 8, ["to"])
          ]),
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.chatSender?.person && _ctx.chatSender?.person.fullName ? _ctx.chatSender?.phone : 'United States'), 1)
        ])
      ])
    ], 2),
    (_ctx.headerBtnsComponent)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.headerBtnsComponent), { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}