import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_auto_size_panel_item_button = _resolveComponent("tm-auto-size-panel-item-button")!
  const _component_chats_system_view_template = _resolveComponent("chats-system-view-template")!

  return (_openBlock(), _createBlock(_component_chats_system_view_template, {
    title: "Instagram account disconnected. You can't send messages to this chat.",
    icon: "tmi-instagram"
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "primary-btn", {}, () => [
        _createVNode(_component_tm_auto_size_panel_item_button, {
          color: "blue",
          text: "Connect Instagram account",
          "btn-size": "",
          icon: "add"
        })
      ]),
      _renderSlot(_ctx.$slots, "other-btn", {}, () => [
        _createVNode(_component_tm_auto_size_panel_item_button, {
          icon: "close",
          text: "Mark as closed",
          "btn-size": ""
        })
      ])
    ]),
    _: 3
  }))
}