
import { computed, defineComponent } from 'vue'
import { useChatsModals, useSidebar } from '@/compositions/chats/chats'
import TmButton from '@/components/shared/TmButton.vue'
import { chatSidebarBulkActionsList } from '@/definitions/chats/sidebar/data'
import type {
  MessengerFilterItemNames,
  ChatSidebarBulkActionsNames
} from '@/definitions/chats/sidebar/types'

type Action = {
  icon: string;
  text: string;
  clickEvent?: () => void;
}

export default defineComponent({
  components: { TmButton },
  setup() {
    const {
      selected,
      getActiveFilter,
    } = useSidebar()
    const {
      assignChat,
      solveChat,
      deleteChat,
    } = useChatsModals()

    const actionsList: Record<ChatSidebarBulkActionsNames, Action> = {
      ...chatSidebarBulkActionsList,
      // перезапись, чтобы добавить clickEvent
      delete: { ...chatSidebarBulkActionsList.delete, clickEvent: () => deleteChat() },
      assign: { ...chatSidebarBulkActionsList.assign, clickEvent: () => assignChat() },
      solve: { ...chatSidebarBulkActionsList.solve, clickEvent: () => solveChat() },
    }

    const actionsListMap: Record<MessengerFilterItemNames, Action[]> = {
      waiting: [actionsList.assign, actionsList.solve],
      open: [actionsList.assign, actionsList.read, actionsList.solve, actionsList.delete],
      unread: [actionsList.assign, actionsList.read, actionsList.solve, actionsList.delete],
      unassigned: [actionsList.assign, actionsList.read, actionsList.solve, actionsList.delete],
      assigned: [actionsList.assign, actionsList.read, actionsList.solve, actionsList.delete],
      solved: [actionsList.reopen, actionsList.assign, actionsList.delete],
      allChats: [actionsList.assign, actionsList.read, actionsList.delete],
    }

    const currentBtnsList = computed<any>(() => actionsListMap[getActiveFilter.value.name])

    return {
      currentBtnsList,
      selected,
    }
  },
})
