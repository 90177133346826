import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-735f1cb5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chats-content-empty" }
const _hoisted_2 = { class: "chats-content-empty__wrapper" }
const _hoisted_3 = { class: "subhead-semi-bold-16 lh-24 mb-2" }
const _hoisted_4 = { class: "body-text-regular-14 lh-20 pre-wrap distinct--text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_tm_icon, {
        name: _ctx.icon,
        "custom-size": "60px"
      }, null, 8, ["name"]),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.text), 1)
    ])
  ]))
}