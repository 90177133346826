
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import { useSidebar } from '@/compositions/chats/chats'

export default defineComponent({
  components: {
    TmButton,
  },
  setup() {
    const {
      hideNewChat,
      searchNewChat,
    } = useSidebar()
    const toInput = ref('')

    return {
      toInput,
      searchNewChat,
      hideNewChat,
    }
  },
})
