
import { defineComponent, computed } from 'vue'
import ChatPreview from '@/components/shared/chat/ChatPreview.vue'
import SearchNoResults from '@/components/shared/search/SearchNoResults.vue'
import { useChats, useSidebar } from '@/compositions/chats/chats'
import type { ChatType } from '@/definitions/shared/chat/types'
import { checkMatch } from '@/services/string'

export default defineComponent({
  components: {
    ChatPreview,
    SearchNoResults,
  },
  setup() {
    const {
      getChatData: chats,
    } = useChats()

    const {
      searchNewChat,
    } = useSidebar()

    const filteredChats = computed(() => {
      return chats.value.filter((e: ChatType) => {
        return checkMatch(e.chatSender.phone, searchNewChat.value) || (e.chatSender.person && checkMatch(e.chatSender.person.fullName, searchNewChat.value))
      })
    })

    const filteredContacts = computed(() => {
      return chats.value.filter((e: ChatType) => {
        if (!e.chatSender.person || !e.chatSender.person.fullName) { return false }
        return checkMatch(e.chatSender.phone, searchNewChat.value) || checkMatch(e.chatSender.person.fullName, searchNewChat.value)
      }).map((e: ChatType) => {
        return {
          ...e,
          notifications: 0,
        }
      })
    })

    return {
      filteredChats,
      filteredContacts,
      searchNewChat,
    }
  },
})
