
import { defineComponent, ref } from 'vue'
import TmFieldsContainer from '@/components/shared/tmFieldsContainer/TmFieldsContainer.vue'
import type { ListChip } from '@/definitions/shared/types'
import { contactListsOptions, tagsOptions } from '@/definitions/_general/_data/optionsList'
import TmButton from '@/components/shared/TmButton.vue'
import { useChats } from '@/compositions/chats/chats'

export default defineComponent({
  components: {
    TmButton,
    TmFieldsContainer,
  },
  setup() {
    const { getActiveChat: activeChat } = useChats()
    const fullName = ref('Kenzi Law')
    const phone = ref([{ phone: '+1 555-0122' }])
    const email = ref('kenzilaw@gmail.com')
    const company = ref('Omnigroup LLC')

    const listsValue = ref<ListChip[]>([contactListsOptions[0]])
    const listsOptions = ref<ListChip[]>([...contactListsOptions])

    const tagsValue = ref([])

    const showMore = ref(false)

    return {
      showMore,
      activeChat,
      tagsValue,
      tagsOptions,
      listsValue,
      listsOptions,
      email,
      company,
      phone,
      fullName,
    }
  },
})
