
import type { PropType } from 'vue'
import { computed, defineComponent } from 'vue'
import type { TextArray } from '@/definitions/shared/types'
import TmStylizedText from '@/components/shared/TmStylizedText.vue'
import type { ChatCallTypes } from '@/definitions/shared/chat/types'

interface SystemMessages {
  [key: string]: TextArray[] | TextArray[][] | string;
}

interface LogsMap {
  [key: string]: TextArray[][]; // Мы не можем использовать ChatCallTypes напрямую, потому что это строковый литерал, но мы можем использовать string
}

const systemMessages: SystemMessages = {
  started: [
    {
      text: 'Today, 11:28 am',
      style: 'bold',
    },
  ],
  created: 'Live chat was created 11:28 am',
  statusWaiting: [
    'Chat status updated to ',
    {
      text: 'Waiting now',
      style: 'bold',
    },
    ' 11:28 am',
  ],
  joinedByOperator: [
    {
      text: 'John Doe',
      style: 'bold',
    },
    ' joined chat 11:29 am',
  ],
  assignedToOperator: [
    'Chat assignee changed to ',
    {
      text: 'John Doe',
      style: 'bold',
    },
    ' 11:29 am',
  ],
  statusOpen: [
    'Chat status updated to ',
    {
      text: 'Open',
      style: 'bold',
    },
    ' 11:29 am',
  ],
  missed: [
    {
      text: 'John Doe',
      style: 'bold',
    },
    ' left this chat 11:29 am',
  ],
  solved: [],
  solvedRated: [
    {
      text: 'Cody Fisher',
      style: 'bold',
    },
    ' rated this chat as Good 11:49 am',
  ],
  solvedCommented: [
    {
      text: 'Cody Fisher',
      style: 'bold',
    },
    ' left a comment “Agent was extra helpful.” 11:49 am',
  ],
  userLeftChat: [
    {
      text: 'Cody Fisher',
      style: 'bold',
    },
    ' left this chat 11:49 am',
  ],
  solvedMarked: [
    {
      text: 'John Doe',
      style: 'bold',
    },
    ' marked this chat as Solved 11:51 am',
  ],
  solvedUpdatedStatus: [
    'Chat status updated to ',
    {
      text: 'Solved',
      style: 'bold',
    },
    ' 11:51 am',
  ],
  solvedUnassigned: [
    'Chat assignee updated to ',
    {
      text: 'Unassigned',
      style: 'bold',
    },
    ' 11:51 am',
  ],
}

const logsMap: LogsMap = {
  start: [
    (systemMessages.started as TextArray[]),
    (systemMessages.created as TextArray[]),
    (systemMessages.statusWaiting as TextArray[]),
  ],
  open: [
    (systemMessages.joinedByOperator as TextArray[]),
    (systemMessages.assignedToOperator as TextArray[]),
    (systemMessages.statusOpen as TextArray[]),
  ],
  missed: [
    (systemMessages.missed as TextArray[]),
  ],
  solved: [
    (systemMessages.solvedRated as TextArray[]),
    (systemMessages.solvedCommented as TextArray[]),
    (systemMessages.userLeftChat as TextArray[]),
    (systemMessages.solvedMarked as TextArray[]),
    (systemMessages.solvedUpdatedStatus as TextArray[]),
    (systemMessages.solvedUnassigned as TextArray[]),
  ],
  ended: [
    (systemMessages.solvedRated as TextArray[]),
    (systemMessages.solvedCommented as TextArray[]),
    (systemMessages.userLeftChat as TextArray[]),
  ],
}

export default defineComponent({
  name: 'ChatsContentSystemLog',
  components: { TmStylizedText },
  props: {
    type: {
      type: String as PropType<ChatCallTypes | 'start'>,
    },
    isStart: {
      type: Boolean,
    },
    colorful: {
      type: Boolean,
    },
  },
  setup(props) {
    const messagesList = computed<TextArray[][]>(
      () => {
        if (props.type) {
          return logsMap[props.type]
        }
        return logsMap.start
      }
    )

    return {
      systemMessages,
      messagesList,
      logsMap,
    }
  },
})
