
import { computed, defineComponent } from 'vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import ActionButton from '@/components/shared/templates/ActionButton.vue'
import { useChatsModals, useSidebar } from '@/compositions/chats/chats'
import { ChatSidebarHeaderDropdownMap } from '@/definitions/chats/sidebar/data'

export default defineComponent({
  components: {
    ActionButton,
    TmDropdown,
    TmDropdownItem,
  },
  emits: ['show-selection'],
  setup(props, { emit }) {
    const { getActiveFilter } = useSidebar()
    const { solveReadChat, solveMissedChat } = useChatsModals()

    const customOptionSelect = {
      ...ChatSidebarHeaderDropdownMap.select,
      clickAction: () => emit('show-selection'),
    }
    const actionsList: any = {
      waiting: [
        customOptionSelect,
        {
          ...ChatSidebarHeaderDropdownMap.solveAllMissed,
          clickAction: solveMissedChat,
        },
      ],
      solved: [customOptionSelect],
      other: [
        customOptionSelect,
        ChatSidebarHeaderDropdownMap.markAsRed,
        {
          ...ChatSidebarHeaderDropdownMap.solveAllRead,
          clickAction: solveReadChat,
        },
      ],
    }
    const currentActionsList = computed<any>(() => actionsList[getActiveFilter.value.name] || actionsList.other)

    return {
      getActiveFilter,
      currentActionsList,
    }
  },
})
