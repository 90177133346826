
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    title: {
      type: String,
      default: 'No conversation selected',
    },
    text: {
      type: String,
      default: 'Please select a chat or start a new one using\n the “New chat” link above.',
    },
    icon: {
      type: String,
      default: 'question_answer',
    },
  },
})
