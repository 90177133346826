
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import ChatSelection from '@/components/shared/chat/ChatSelection.vue'
import { useContent } from '@/compositions/chats/chats'

export default defineComponent({
  components: {
    TmButton,
    ChatSelection,
  },
  setup() {
    const {
      showSelection,
      selected,
      isAllSelected,
      toggleAllSelected,
    } = useContent()

    const hideSelection = () => {
      toggleAllSelected(true)
      showSelection.value = false
    }

    return {
      hideSelection,
      selected,
      isAllSelected,
      toggleAllSelected,
    }
  },
})
