
import { defineComponent } from 'vue'
import TmSkeleton from '@/components/shared/TmSkeleton.vue'

export default defineComponent({
  components: {
    TmSkeleton,
  },
  setup() {

  },
})
