
import { defineComponent, computed } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import { useModals } from '@/compositions/modals'
import { useChats, useCalls, useChatsModals, useContent } from '@/compositions/chats/chats'
import useContactsOpenModal from '@/compositions/contacts/useContactsOpenModal'
import ActionButton from '@/components/shared/templates/ActionButton.vue'
import { useRoute } from 'vue-router'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'

export default defineComponent({
  components: {
    TmDropdownItem,
    ActionButton,
    TmDropdown,
    TmButton,
  },
  setup() {
    const { openModal } = useModals()
    const { openBlockContactModal, openUnsubscribeContactModal, openBlockIPModal } = useContactsOpenModal()
    const route = useRoute()

    const { showSearch, showSelection } = useContent()

    const { solveChat, deleteChat, assignChat, assigneeModal } = useChatsModals()

    const {
      getActiveCall: activeCall,
      setActiveCall,
      showCall,
    } = useCalls()

    const {
      getActiveChat: activeChat,
      showChatDetails,
    } = useChats()

    const primaryBtn = computed(() => {
      const isSolved = route.name === 'base.chats.solved'

      return {
        text: isSolved ? 'Reopen' : 'Solve',
        icon: isSolved ? 'refresh' : 'tmi-task-circle',
        clickAction: isSolved ? null : solveChat,
      }
    })

    const openChatsDownload = () => {
      openModal('singleInput', {
        label: 'Email',
        placeholder: 'Enter email',
        value: 'jamesbrown@gmail.com',
        description: 'Export of data can take some time. Please enter email address, and we will send a download link as soon as possible.',
        modalTitle: 'Send the download link to an email',
        btnText: 'Send',
      })
    }

    const chatSender = computed(() => activeChat.value?.chatSender || null)
    const openBlockModal = () => openBlockContactModal(
      (chatSender.value?.person?.fullName ? chatSender.value?.person?.fullName : chatSender.value?.phone) || 'John Doe'
    )
    const initCall = () => {
      showCall.value = true
      setActiveCall({
        chatSender: chatSender.value!,
        status: 'waiting',
        type: 'outbound',
        duration: '1:20',
        muted: false,
      })

      setTimeout(() => {
        if (activeCall.value) {
          setActiveCall({
            ...activeCall.value,
            status: 'inProgress',
          })
        }
      }, 2000)
    }

    const selectMessages = () => {
      showSelection.value = true
    }
    const searchMessages = () => {
      showSearch.value = true
    }

    const createTask = () => {
      openModal('tasks', {
        description: 'Related to the chat 332211 with Cody Fisher.',
      })
    }

    return {
      createTask,
      initCall,
      deleteChat,
      openChatsDownload,
      solveChat,
      showChatDetails,
      openUnsubscribeContactModal,
      primaryBtn,
      openBlockModal,
      openBlockIPModal,
      assignChat,
      assigneeModal,
      searchMessages,
      selectMessages,
    }
  },
})
