import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b981fe76"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chats-content-log-loading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_skeleton = _resolveComponent("tm-skeleton")!
  const _component_tm_scrollbar = _resolveComponent("tm-scrollbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_scrollbar, null, {
      default: _withCtx(() => [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(8, (i) => {
          return _createVNode(_component_tm_skeleton, _mergeProps({
            key: i,
            "random-width": "",
            class: {
          'mt-6': i,
        }
          }, _ctx.getStyles()), null, 16, ["class"])
        }), 64))
      ]),
      _: 1
    })
  ]))
}