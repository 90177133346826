import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28e95f8a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chats-sidebar-actions" }
const _hoisted_2 = { class: "body-text-semi-bold-12 lh-16 distinct--text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentBtnsList, (action, i) => {
      return (_openBlock(), _createBlock(_component_tm_button, {
        key: i,
        class: _normalizeClass(["chats-sidebar-actions__action", {
        'chats-sidebar-actions__action--disabled': !_ctx.selected.length,
      }]),
        flat: "",
        color: "transparent",
        onClick: action.clickEvent
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_icon, {
            name: action.icon,
            size: "xxxLarge",
            class: "distinct--text"
          }, null, 8, ["name"]),
          _createElementVNode("div", _hoisted_2, _toDisplayString(action.text), 1)
        ]),
        _: 2
      }, 1032, ["class", "onClick"]))
    }), 128))
  ]))
}