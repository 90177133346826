import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e0a1c98c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chats-sidebar-empty" }
const _hoisted_2 = { class: "headline-18 lh-22 mb-2 neutral--text" }
const _hoisted_3 = { class: "body-text-regular-14 pre-wrap lh-20 distinct--text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_icon, {
      name: _ctx.icon,
      class: "subtle--text mb-4",
      "custom-size": "60px"
    }, null, 8, ["name"]),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.text), 1),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}