import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0854254a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chats-sidebar-contacts" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chat_preview = _resolveComponent("chat-preview")!
  const _component_tm_scrollbar = _resolveComponent("tm-scrollbar")!
  const _component_search_no_results = _resolveComponent("search-no-results")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.chats.length)
      ? (_openBlock(), _createBlock(_component_tm_scrollbar, {
          key: 0,
          "remove-scrollbar-width": ""
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chats, (chat, i) => {
              return (_openBlock(), _createBlock(_component_chat_preview, _mergeProps(chat, {
                key: i,
                selected: chat.selected,
                "onUpdate:selected": ($event: any) => ((chat.selected) = $event),
                search: _ctx.search,
                "show-selection": _ctx.showSelection,
                pinned: chat.pinned,
                active: _ctx.activeChat === chat,
                "onUpdate:pinned": ($event: any) => (_ctx.updateChat({ ...chat, pinned: !chat.pinned })),
                onClick: ($event: any) => (_ctx.handleClick(chat))
              }), null, 16, ["selected", "onUpdate:selected", "search", "show-selection", "pinned", "active", "onUpdate:pinned", "onClick"]))
            }), 128))
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_search_no_results, { key: 1 }))
  ]))
}