import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40ed9c82"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex w100pr justify-between align-center" }
const _hoisted_2 = { class: "d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chats_sidebar_filter = _resolveComponent("chats-sidebar-filter")!
  const _component_action_button = _resolveComponent("action-button")!
  const _component_chats_sidebar_header_actions = _resolveComponent("chats-sidebar-header-actions")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_chats_sidebar_filter, { class: "ml-n10px" }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_action_button, {
        icon: "tmi-edit-square",
        text: "New chat",
        onClick: _ctx.showNewChat
      }, null, 8, ["onClick"]),
      _createVNode(_component_action_button, {
        icon: "tmi-settings-outline",
        text: "Settings",
        onClick: _ctx.openMessengerSettings
      }, null, 8, ["onClick"]),
      _createVNode(_component_chats_sidebar_header_actions, {
        onShowSelection: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showSelection = true))
      })
    ])
  ]))
}