
import { defineComponent, onMounted, onUnmounted, ref, watch } from 'vue'
import TmExpansion from '@/components/shared/TmExpansion.vue'
import ActionButton from '@/components/shared/templates/ActionButton.vue'
import { useChats } from '@/compositions/chats/chats'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmSearch from '@/components/shared/TmSearch.vue'
import ChatDetailsInfo from '@/components/pages/chats/details/ChatDetailsInfo.vue'
import ChatDetailsContact from '@/components/pages/chats/details/ChatDetailsContact.vue'
import SearchNoResults from '@/components/shared/search/SearchNoResults.vue'
import DetailsSidebarActivities from '@/components/shared/templates/details/sidebar/DetailsSidebarActivities.vue'
import DetailsSidebarNotes from '@/components/shared/templates/details/sidebar/DetailsSidebarNotes.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: {
    SearchNoResults,
    ChatDetailsContact,
    ChatDetailsInfo,
    DetailsSidebarNotes,
    DetailsSidebarActivities,
    ActionButton,
    TmButton,
    TmSearch,
    TmScrollbar,
    TmExpansion,
  },
  setup() {
    const { showChatDetails, getActiveChat: activeChat } = useChats()
    const { isXXlMax, isSmMax } = useBreakpoints()
    const chatRef = ref()

    const resizerRef = ref()
    const sidebarContainerRef = ref()
    const sidebarTransition = ref(true)
    const sidebarWidth = ref()

    let resizerElement: HTMLElement
    let resizeObserver: ResizeObserver

    const styleUpdate = (elementWidth: number) => {
      const parentWidth = sidebarContainerRef.value.parentElement.offsetWidth

      const minWidth = isXXlMax.value ? parentWidth * 0.4 : parentWidth * 0.25
      const maxWidth = isXXlMax.value ? parentWidth * 0.5 : parentWidth * 0.3

      sidebarWidth.value = isSmMax.value ? 300 : elementWidth <= minWidth ? minWidth : elementWidth >= maxWidth ? maxWidth : elementWidth
    }

    const stopResize = () => {
      sidebarTransition.value = true
      window.removeEventListener('mousemove', resize)
    }

    const resize = (e: MouseEvent) => {
      const offset = sidebarContainerRef.value.offsetWidth - (e.pageX - sidebarContainerRef.value.getBoundingClientRect().left)
      sidebarTransition.value = false

      styleUpdate(offset)
    }

    const onResizerMouseDown = (e: MouseEvent) => {
      e.preventDefault()
      window.addEventListener('mousemove', resize)
      window.addEventListener('mouseup', stopResize)
    }

    onMounted(() => {
      resizerElement = resizerRef.value

      resizeObserver = new ResizeObserver(() => {
        styleUpdate(sidebarWidth.value)
      })

      if (sidebarContainerRef.value) {
        resizeObserver.observe(sidebarContainerRef.value)
      }

      resizerElement.addEventListener('mousedown', onResizerMouseDown)
    })

    onUnmounted(() => {
      resizerElement.removeEventListener('mousedown', onResizerMouseDown)
      resizeObserver.disconnect()
    })

    watch(() => activeChat.value, (newVal, oldVal) => {
      if (!newVal || !oldVal) { return }
      if (oldVal.id !== newVal.id) {
        chatRef.value?.show()
      }
    })

    const showSearch = ref(false)
    const search = ref('')
    const activities = ref([
      {
        title: 'Issue with API and documentation',
        type: 'email',
        date: 'Today, 10:24 am',
        status: 'past',
      },
      {
        title: 'Chat with (754) 874-4758',
        type: 'mobileChat',
        status: 'open',
        date: '2 hours ago',
      },
      {
        title: 'Live chat request',
        type: 'chat',
        status: 'open',
        date: '2 hours ago',
      },
    ])

    return {
      activities,
      showSearch,
      search,
      chatRef,
      showChatDetails,
      activeChat,
      resizerRef,
      sidebarContainerRef,
      sidebarTransition,
      sidebarWidth,
    }
  },
})
