
import { defineComponent } from 'vue'
import TmButtonGroup from '@/components/shared/TmButtonGroup.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmBadgeCounter from '@/components/shared/TmBadgeCounter.vue'

export default defineComponent({
  name: 'ChatsSidebarTakeRequest',
  components: {
    TmBadgeCounter,
    TmDropdown,
    TmTooltip,
    TmDropdownItem,
    TmButton,
    TmButtonGroup,
  },
})
