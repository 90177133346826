
import { defineComponent } from 'vue'
import TmSkeleton from '@/components/shared/TmSkeleton.vue'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    TmSkeleton,
  },
  setup() {
    const { isLoadingMode } = useModes()

    return {
      isLoadingMode,
    }
  },
})
