
import { defineComponent, computed } from 'vue'
import ChatsContentHeaderMain from '@/components/pages/chats/content/header/ChatsContentHeaderMain.vue'
import ChatsContentHeaderEmail from '@/components/pages/chats/content/header/ChatsContentHeaderEmail.vue'
import ChatsContentHeaderSearch from '@/components/pages/chats/content/header/ChatsContentHeaderSearch.vue'
import ChatsContentHeaderSelection from '@/components/pages/chats/content/header/ChatsContentHeaderSelection.vue'
import ChatsContentHeaderDate from '@/components/pages/chats/content/header/ChatsContentHeaderDate.vue'
import ChatsContentHeaderEmpty from '@/components/pages/chats/content/header/ChatsContentHeaderEmpty.vue'
import ChatsContentHeaderLoading from '@/components/pages/chats/content/header/ChatsContentHeaderLoading.vue'
import { useChats, useContent } from '@/compositions/chats/chats'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    ChatsContentHeaderMain,
    ChatsContentHeaderEmail,
    ChatsContentHeaderSelection,
    ChatsContentHeaderLoading,
    ChatsContentHeaderEmpty,
    ChatsContentHeaderSearch,
    ChatsContentHeaderDate,
  },
  setup() {
    const { getActiveChat: activeChat } = useChats()
    const { isEmptyMode, isLoadingMode } = useModes()
    const {
      showSearch,
      showDate,
      showSelection,
    } = useContent()

    const headerComponent = computed((): string => {
      if (isLoadingMode.value || !activeChat.value) {
        return 'chats-content-header-loading'
      }

      if (isEmptyMode.value) {
        return 'chats-content-header-empty'
      }

      if (showSearch.value) {
        return 'chats-content-header-search'
      }

      if (showDate.value) {
        return 'chats-content-header-date'
      }

      if (showSelection.value) {
        return 'chats-content-header-selection'
      }

      if (activeChat.value.type === 'email') {
        return 'chats-content-header-email'
      }

      return 'chats-content-header-main'
    })

    return {
      headerComponent,
    }
  },
})
