
import { defineComponent } from 'vue'
import { useSidebar } from '@/compositions/chats/chats'
import ChatsSidebarHeaderSearch from '@/components/pages/chats/sidebar/header/ChatsSidebarHeaderSearch.vue'
import ChatsSidebarHeaderCompose from '@/components/pages/chats/sidebar/header/ChatsSidebarHeaderCompose.vue'
import ChatsSidebarHeaderTop from '@/components/pages/chats/sidebar/header/ChatsSidebarHeaderTop.vue'

export default defineComponent({
  components: {
    ChatsSidebarHeaderTop,
    ChatsSidebarHeaderCompose,
    ChatsSidebarHeaderSearch,
  },
  setup() {
    const {
      newChatVisible,
    } = useSidebar()

    return {
      newChatVisible,
    }
  },
})
