
import { defineComponent, computed } from 'vue'
import ChatAvatar from '@/components/shared/chat/ChatAvatar.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmEllipsis from '@/components/shared/TmEllipsis.vue'
import { useModals } from '@/compositions/modals'
import { useBreakpoints } from '@/compositions/breakpoints'
import { useContent, useChats, useCalls } from '@/compositions/chats/chats'
import { senderSettingsOptions } from '@/definitions/_general/_data/optionsList'
import { tmAutoSizeIgnoreClass } from '@/definitions/shared/autoSize/data'
import useContactsOpenModal from '@/compositions/contacts/useContactsOpenModal'
import ActionButton from '@/components/shared/templates/ActionButton.vue'
import { singleWindow } from '@/services/singleWindow'

export default defineComponent({
  components: {
    ActionButton,
    TmEllipsis,
    ChatAvatar,
    TmDropdown,
    TmDropdownItem,
    TmButton,
  },
  setup() {
    const { isSmMax, isMdMax } = useBreakpoints()
    const { openModal } = useModals()
    const { openBlockContactModal, openUnsubscribeContactModal } = useContactsOpenModal()

    const {
      showSearch,
      showDate,
      showSelection,
    } = useContent()

    const {
      getActiveCall: activeCall,
      setActiveCall,
      showCall,
    } = useCalls()

    const {
      showMobileChat,
      updateChat,
      setActiveChat,
      getActiveChat: activeChat,
      showChatDetails,
    } = useChats()

    const openChatsDownload = () => {
      openModal('singleInput', {
        label: 'Email',
        placeholder: 'Enter email',
        value: 'jamesbrown@gmail.com',
        description: 'Export of data can take some time. Please enter email address, and we will send a download link as soon as possible.',
        modalTitle: 'Send the download link to an email',
        btnText: 'Send',
      })
    }

    const openSenderSettings = () => {
      openModal('selectSearchCountry', {
        label: 'Default sender',
        modalTitle: 'Chats sender',
        options: senderSettingsOptions,
      })
    }

    const chatSender = computed(() => activeChat.value ? activeChat.value.chatSender : null)

    const initCall = () => {
      showCall.value = true
      setActiveCall({
        chatSender: chatSender.value!,
        status: 'waiting',
        type: 'outbound',
        duration: '1:20',
        muted: false,
      })

      setTimeout(() => {
        if (activeCall.value) {
          setActiveCall({
            ...activeCall.value,
            status: 'inProgress',
          })
        }
      }, 2000)
    }

    const openInWindow = () => {
      singleWindow(900, 600, '/chat-window')
    }

    return {
      openInWindow,
      initCall,
      showSearch,
      activeCall,
      isMdMax,
      activeChat,
      setActiveChat,
      updateChat,
      useModals,
      openModal,
      isSmMax,
      chatSender,
      showMobileChat,
      openChatsDownload,
      showDate,
      tmAutoSizeIgnoreClass,
      showSelection,
      openSenderSettings,
      showChatDetails,
      openBlockContactModal,
      openUnsubscribeContactModal,
    }
  },
})
