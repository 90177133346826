
import { defineComponent } from 'vue'
import ChatsSystemViewTemplate from '@/components/pages/chats/content/systemViews/ChatsSystemViewTemplate.vue'
import TmAutoSizePanelItemButton from '@/components/shared/autoSize/panel/TmAutoSizePanelItemButton.vue'

export default defineComponent({
  name: 'ChatsSystemViewInstagramDisconnected',
  components: {
    ChatsSystemViewTemplate,
    TmAutoSizePanelItemButton,
  },
})
