
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import { useContent } from '@/compositions/chats/chats'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: {
    TmButton,
  },
  setup() {
    const {
      showDate,
      from,
      to,
    } = useContent()
    const { isSmMax } = useBreakpoints()

    const hideDate = () => {
      showDate.value = false
      from.value = null
      to.value = null
    }

    return {
      showDate,
      from,
      hideDate,
      isSmMax,
      to,
    }
  },
})
