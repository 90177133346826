
import { defineComponent } from 'vue'
import TmSkeleton from '@/components/shared/TmSkeleton.vue'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'
import { randomIntFromInterval } from '@/services/utils'

export default defineComponent({
  components: {
    TmScrollbar,
    TmSkeleton,
  },
  setup() {
    const getStyles = () => {
      if (randomIntFromInterval(0, 3) === 3) {
        return {
          type: 'QChip',
          height: '10px',
          class: 'mr-auto ml-auto',
        }
      }

      return {
        type: 'QChip',
        height: '60px',
        class: randomIntFromInterval(0, 1) === 1 ? '' : 'ml-auto',
      }
    }

    return {
      getStyles,
    }
  },
})
