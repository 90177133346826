
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import ChatSelection from '@/components/shared/chat/ChatSelection.vue'
import type { ChatType } from '@/definitions/shared/chat/types'
import { useSidebar, useChats } from '@/compositions/chats/chats'
import ChatsSidebarActions from '@/components/pages/chats/sidebar/ChatsSidebarActions.vue'

export default defineComponent({
  components: {
    ChatsSidebarActions,
    TmButton,
    ChatSelection,
  },
  setup() {
    const {
      isAllSelected,
      showSelection,
    } = useSidebar()

    const {
      updateChats,
      getChatData: chats,
    } = useChats()

    const toggleAllSelected = (): void => {
      const updatedChats = chats.value.map((e: ChatType) => {
        return {
          ...e,
          selected: !isAllSelected.value,
        }
      })

      updateChats(updatedChats)
    }

    return {
      isAllSelected,
      toggleAllSelected,
      showSelection,
    }
  },
})
