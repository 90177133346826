
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { useSidebar } from '@/compositions/chats/chats'
import ChatsSidebarFilter from '@/components/pages/chats/sidebar/ChatsSidebarFilter.vue'
import ActionButton from '@/components/shared/templates/ActionButton.vue'
import ChatsSidebarHeaderActions from '@/components/pages/chats/sidebar/header/ChatsSidebarHeaderActions.vue'

export default defineComponent({
  components: {
    ChatsSidebarFilter,
    ActionButton,
    ChatsSidebarHeaderActions,
  },
  setup() {
    const router = useRouter()
    const openMessengerSettings = () => router.push({ name: 'base.chats.settings' })

    const {
      showNewChat,
      showSelection,
    } = useSidebar()

    return {
      openMessengerSettings,
      router,
      showNewChat,
      showSelection,
    }
  },
})
