<template>
  <div class="d-flex align-center flex-grow-1 justify-center flex-column">
    <tm-icon
      name="forum"
      custom-size="80px"
      class="gray4--text mb-4"
    />
    <div class="page-title-20 mb-1 lh-24">
      There are no messages in this chat yet
    </div>
    <div class="body-text-regular-14 lh-20 distinct--text">
      To send an SMS, type your message below and click "Send".
    </div>
  </div>
</template>

<style lang="scss" scoped>
.gray4--text {
  color: $gray4;
}
</style>
